<div class="customized-snackbar-container">
  <div class="icon-and-text">
    <!-- leading icon part -->
    <div class="custmized-snackbar-icon">
      @switch (data.snackBarType) {
        @case ('success-message-snackbar') {
          <div>
            <mat-icon class="material-icons">check_circle</mat-icon>
          </div>
        }
        @case ('error-snackbar') {
          <div>
            <mat-icon class="material-icons">error</mat-icon>
          </div>
        }
        @case ('info-message-snackbar') {
          <div>
            <mat-icon class="material-icons">info</mat-icon>
          </div>
        }
      }
    </div>
    <!-- text part -->
    <div class="custmized-snackbar-text">
      {{data.msg}}
    </div>
  </div>
  <!-- trailing icon part -->
  <div (click)="closeSnackBar()" class="custmized-snackbar-close">
    <mat-icon>close</mat-icon>
  </div>
</div>
