
import { Component, Inject, OnInit, } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-customized-snackbar',
  templateUrl: './customized-snackbar.component.html',
  styleUrls: ['./customized-snackbar.component.scss'],
  imports: [MatIconModule],
  standalone:true,
})
export class CustomizedSnackbarComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackbar:  MatSnackBar
    ) {
    this.data = data;
  }
  ngOnInit(): void {

  }

  closeSnackBar(): void{
      this.snackbar.dismiss();
  }
}
